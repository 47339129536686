.el-table[data-v-25aac758] {
  width: 99%;
  float: right;
}
.actived[data-v-25aac758] {
  background-color: #c3e5e3 !important;
  font-weight: 600;
}
.liTitle[data-v-25aac758] {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 11px;
  margin-top: 10px;
  text-align: left;
}
.ulStyle[data-v-25aac758] {
  margin-right: 20px;
}
.ulStyle li[data-v-25aac758] {
  text-align: left;
  cursor: pointer;
  height: 26px;
  font-size: 13px;
  line-height: 26px;
  border-radius: 7px;
}
li[data-v-25aac758]:hover {
  background-color: #f0f9f8;
}
.left[data-v-25aac758] {
  width: 15%;
  min-height: calc(94vh - 100px);
  display: inline-block;
  border-right: 1px solid #e8e8e8;
}
.right[data-v-25aac758] {
  float: right;
  width: 85%;
}
.contentLi[data-v-25aac758] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.li_left[data-v-25aac758] {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}