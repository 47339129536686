.over-flow[data-v-f5b42a92] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wid190[data-v-f5b42a92] {
  width: 80%;
}
p[data-v-f5b42a92] {
  margin: 0;
}